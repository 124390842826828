<template>
<PlanningToSchedule/>
</template>

<script>
import PlanningToSchedule from '../../components/app/planning-to-schedule/PlanningToSchedule.vue'

  export default {
    components: {
    PlanningToSchedule
    }

  }
</script>
