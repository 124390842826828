var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":4000,"top":"","transition":"scroll-y-transition","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.successSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" ОК ")])]}}]),model:{value:(_vm.successSnackbar),callback:function ($$v) {_vm.successSnackbar=$$v},expression:"successSnackbar"}},[_vm._v(" Заявка на оформление отсутствия № "+_vm._s(_vm.result.elementId)+" успешно создана! ")]),_c('v-snackbar',{attrs:{"top":"","timeout":-1,"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.errorSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" ОК ")])]}}]),model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" Ошибка ("+_vm._s(_vm.result)+") обратитесь к администратору!!! ")]),_c('div',{staticClass:"wrapper-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('p',{staticClass:"subtitle-1 font-weight-medium black--text mt-4 mb-4"},[_vm._v("Информация по отсутствию")]),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","xs":"12","sm":"8","md":"6"}},[_c('UserInput',{attrs:{"error-messages":_vm.userErrors},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","xs":"12","sm":"8","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Даты отсутствия","prepend-icon":"mdi-calendar","error-messages":_vm.dateRangeTextErrors,"readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","locale":"ru","selected-items-text":"период","first-day-of-week":"1"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-select',{attrs:{"items":_vm.reasonAbsenceItems,"label":"Причина отсутствия"},model:{value:(_vm.reasonAbsence),callback:function ($$v) {_vm.reasonAbsence=$$v},expression:"reasonAbsence"}}),_c('v-textarea',{attrs:{"label":"Комментарий (необязательно)","auto-grow":""},on:{"input":function($event){return _vm.$v.description.$touch()},"blur":function($event){return _vm.$v.description.$touch()}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}}),_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"color":"primary","type":"submit","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Оставить заявку ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }