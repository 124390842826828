var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":4000,"top":"","transition":"scroll-y-transition","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.successSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" ОК ")])]}}]),model:{value:(_vm.successSnackbar),callback:function ($$v) {_vm.successSnackbar=$$v},expression:"successSnackbar"}},[_vm._v(" Заявка на оплату счетов № "+_vm._s(_vm.result.elementId)+" успешно создана! ")]),_c('v-snackbar',{attrs:{"top":"","timeout":-1,"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.errorSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" ОК ")])]}}]),model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" Ошибка ("+_vm._s(_vm.result)+") обратитесь к администратору!!! ")]),_c('div',{staticClass:"wrapper-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('p',{staticClass:"subtitle-1 font-weight-medium black--text mt-4 mb-4"},[_vm._v("Информация по оплате")]),_c('v-combobox',{ref:"purpose",attrs:{"items":_vm.purposeItems,"autofocus":"","label":"Назначение платежа","error-messages":_vm.purposeErrors,"append-icon":"","dense":""},on:{"blur":function($event){return _vm.$v.purpose.$touch()}},model:{value:(_vm.purpose),callback:function ($$v) {_vm.purpose=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"purpose"}}),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"sm":"4","xm":"12"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],ref:"money",attrs:{"label":"Сумма","error-messages":_vm.moneyErrors,"suffix":"руб","prepend-icon":"mdi-cash-multiple"},on:{"blur":function($event){return _vm.$v.money.$touch()}},model:{value:(_vm.money),callback:function ($$v) {_vm.money=$$v},expression:"money"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Требуемая дата оплаты","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateText),callback:function ($$v) {_vm.dateText=$$v},expression:"dateText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":new Date().toISOString().substr(0, 10),"locale":"ru","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-textarea',{attrs:{"label":"Комментарий к оплате (необязательно)","auto-grow":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}}),_c('v-file-input',{attrs:{"multiple":"","id":"test_file_input","label":"Документы (необязательно)","counter":"","prepend-icon":"mdi-paperclip"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('p',{staticClass:"subtitle-1 font-weight-medium black--text mt-10 mb-4"},[_vm._v("Дополнительная информация")]),_c('DealInput',{model:{value:(_vm.deal),callback:function ($$v) {_vm.deal=$$v},expression:"deal"}}),_c('ProjectInput',{model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('TaskInput',{model:{value:(_vm.taskId),callback:function ($$v) {_vm.taskId=$$v},expression:"taskId"}}),_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"color":"primary","type":"submit","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Оставить заявку ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }